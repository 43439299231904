import React from "react";
import UIButton from "@pagepro-monorepo/ui/lib/components/atoms/Button";
import { storyblokEditable } from "@storyblok/react";

import ButtonLink from "@components/atoms/ButtonLink";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({ blok }) => {
  const {
    label,
    link: {
      url,
      cached_url: cachedUrl,
      email,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    } = {},
    mode: $mode,
    size,
    icon: svgIcon
  } = blok;

  const newestUrl = fullSlug || cachedUrl;
  const href = email ? `mailto:${email}` : url || newestUrl;
  const icon = svgIcon?.filename
    ? tryToReplaceDomain(svgIcon?.filename)
    : undefined;

  return href ? (
    <ButtonLink
      {...{
        $mode,
        size,
        href,
        rel,
        target,
        icon,
        ...storyblokEditable(blok)
      }}
    >
      {label}
    </ButtonLink>
  ) : (
    <UIButton {...{ $mode, size, icon, ...storyblokEditable(blok) }}>
      {label}
    </UIButton>
  );
};

export default Button;
